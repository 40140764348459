<template>
    <!-- <a :href="article.path" class="link-news no-decoration" :title="article.title"> -->
    <div class="card card-news">
        <div class="row" style="padding: 0; margin: 0;">
            <div class="col-12 col-lg-4 card-image" style="padding: 0; margin: 0;">
                <a :href="article.path" class="fullsize no-decoration" :title="article.title">
                    <img class="card-img-top img-center" v-lazy-load :data-src="article.img" :alt="article.alt" />

                    <span v-if="displayTag" class="badge badge-secondary tag">{{ capital(article.type) }}</span>
                </a>
            </div>
            <div class="col-12 col-lg-8 card-desc" style="padding: 0; margin: 0;">
                <div class="card-body">
                    <a :href="article.path" class="fullsize no-decoration" :title="article.title">
                        <h2 class="card-title">{{ article.title }}</h2>
                        <p class="card-text">{{ article.description }}</p>
                    </a>
                    <p class="card-footer card-metadata">
                        <span v-if="article.date" class="posted-on float-left">
                            <i class="far fa-calendar-alt"></i>
                            <time :datetime="article.date">{{ dateFormat(article.date) }}</time>
                        </span>

                        <span v-if="article.readingTime" class="readtime">
                            <i class="far fa-clock"></i>
                            <span class="unecessary">Lecture :</span>
                            <span>{{ getReadingTime(article.readingTime) }} min</span>
                        </span>

                        <span v-if="article.author" class="author-span float-right">
                            <i class="far fa-user"></i>
                            <span class="unecessary">par </span>
                            <span class="author vcard" itemprop="author" itemscope="" itemtype="http://schema.org/Person">
                                <a :href="'/auteur/' + this.article.author" itemprop="url" rel="author">
                                    <span itemprop="name">{{ article.author }}</span>
                                </a>
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- </a> -->
</template>

<script>
import moment from "moment";

export default {
    props: ["article", "displayTag"],
    methods: {
        dateFormat(unixDate) {
            return moment
                .unix(unixDate)
                .locale("fr")
                .format("ll");
        },

        getReadingTime(n) {
            let readingTime = parseInt(n / 60 / 1000);
            return readingTime >= 1 ? readingTime : 1;
        },

        // set first letter as capital
        capital(s) {
            return s.charAt(0).toUpperCase() + s.slice(1);
        }
    }
};
</script>

<style>
.card-news {
    width: 100%;
    position: relative;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.card-news img {
    height: 100%;
    width: 100%;
    padding: 0;
    object-fit: cover;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-news h2 {
    margin-top: 0px;
    font-size: 20px;
}

.card-news .card-body {
    position: relative;
    height: 200px;
    padding-bottom: 25px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.card-news .card-metadata {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0px;
    color: grey;
    text-align: center;
    background-color: #fff;
    border-radius: none;
    border-bottom-right-radius: calc(0.25rem - 1px);
}

.card-news .card-image {
    position: relative;
    padding-right: 0;
    padding-left: 0;
    background-color: white;
}

.card-image span.badge {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 10px;
    margin-bottom: 10px;
}

.card-news .card-desc {
    padding-left: 0;
}

.card-news h2.card-title {
    font-size: 20px;
    margin-bottom: 20px;
    max-lines: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.card-news .card-text {
    max-lines: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.card-news {
    height: 200px;
}

.card-news .row {
    height: 100%;
}

.card-news .card {
    margin-bottom: 20px;
}

.card-news .unecessary {
    display: none;
}

.card-image {
    overflow: hidden;
}

.card-news:hover .card-image img {
    transition: transform 1.5s ease;
    transform: scale(1.2);
}

a.fullsize {
    width: 100%;
    height: 100%;
}

@media (max-width: 1024px) {
    .card-news {
        height: auto;
    }

    .card-news .card-image {
        height: 300px;
        padding-right: 15px;
    }

    .card-news .card-desc {
        height: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .card-news .card-metadata {
        height: 40px;
        margin: 0px;
    }

    .card-news .card-metadata span {
        height: 28px;
        vertical-align: top;
        display: inline-block;
    }

    .card-news .card-metadata span.unecessary {
        display: none;
    }
}
</style>
