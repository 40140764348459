<template>
    <div>
        <section class="header">
            <div class="container-fluid d-computer">
                <div class="row mt-4">
                    <div class="title-container mx-auto">
                        <h1 class="title" style="padding-top: 25px;">Stakepool.fr</h1>
                        <h2>Le média crypto indépendant</h2>
                        <p class="subtitle">financé par vos délégations</p>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col col-lg-3" style="border-right: 1px solid rgba(0, 0, 0, 0.1);">
                        <article-shortcut :article="allArticles[2]"></article-shortcut>
                    </div>
                    <div class="col col-lg-3" style="border-top: 1px solid rgba(0, 0, 0, 0.1);">
                        <article-shortcut :article="allArticles[0]"></article-shortcut>
                    </div>
                    <div class="col col-lg-3" style="border-left: 1px solid rgba(0, 0, 0, 0.1);">
                        <article-shortcut :article="allArticles[1]"></article-shortcut>
                    </div>
                    <div class="col col-lg-3" style="border-left: 1px solid rgba(0, 0, 0, 0.1);">
                        <article-shortcut :article="allArticles[3]"></article-shortcut>
                    </div>
                </div>
            </div>

            <div class="container-fluid d-mobile">
                <div class="title-container">
                    <h1 class="title" style="padding-top: 25px;">Stakepool.fr</h1>
                    <h2>Le média crypto indépendant</h2>
                    <p class="subtitle">financé par vos délégations</p>
                </div>
            </div>
        </section>

        <section class="news">
            <div class="main news-layout">
                <div class="container-fluid d-computer">
                    <div class="row" style="padding-bottom: 50px;">
                        <div class="col-12">
                            <div class="news">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <h2 class="container-title">News</h2>
                                        <article-card v-for="article in newsList" :key="article.path" :article="article"></article-card>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <h2 class="container-title">Articles</h2>
                                        <article-card v-for="article in articlesList" :key="article.path" :article="article"></article-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid d-mobile">
                    <div class="row" style="padding-bottom: 50px;">
                        <div class="col-12">
                            <div class="news">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="container-title">L'actualité crypto</h2>
                                        <article-card v-for="article in allArticles" :key="article.path" :article="article" displayTag="true"></article-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import createHeader from "../js/meta.js";
import { buildNewsMenu } from "../js/menu.js";
import { SidebarMenu } from "vue-sidebar-menu";
import moment from "moment";
import ArticleCard from "../components/ArticleCard";
import ArticleShortcut from "../components/ArticleShortcut";
import _ from "lodash";

export default {
    layout: "DefaultLayout",
    components: {
        SidebarMenu,
        ArticleCard,
        ArticleShortcut
    },
    head: createHeader({
        title: "L'actualité crypto indépendante - Stakepool.fr",
        description:
            "Nos analyses et actualités crypto, financées par vos délégations ADA sur Cardano, sur Stakepool.fr"
    }),
    mounted: () => {
        $(".sidebar").show();
    },
    data() {
        return {
            menu: []
        };
    },
    methods: {
        dateFormat(unixDate) {
            return moment
                .unix(unixDate)
                .locale("fr")
                .format("ll");
        }
    },
    async asyncData({ $content, params }) {
        const newsList = await $content("actu")
            .only([
                "title",
                "path",
                "img",
                "description",
                "date",
                "author",
                "readingTime"
            ])
            .sortBy("date", "desc")
            .fetch();
        const articlesList = await $content("articles")
            .only([
                "title",
                "path",
                "img",
                "description",
                "date",
                "author",
                "readingTime"
            ])
            .sortBy("date", "desc")
            .fetch();

        _.each(newsList, n => (n.type = "news"));
        _.each(articlesList, a => (a.type = "article"));

        let allArticles = newsList.concat(articlesList);
        allArticles = allArticles.sort((a, b) => b.date - a.date);

        return {
            menu: await buildNewsMenu($content),
            articlesList,
            newsList,
            allArticles
        };
    }
};
</script>

<style>
.highlight {
    background-color: #e4e4e4;
}

.section-mission {
    margin-bottom: 40px;
}

.section-doc {
    margin-bottom: 40px;
}

.section-help .col-12 {
    margin-bottom: 40px;
}

section ul {
    margin: 0;
}

section ul {
    list-style-type: none;
}

section ul > li {
    text-indent: -30px;
}

section ul > li:before {
    content: "-";
    text-indent: -30px;
}

#delegate {
    padding: 30px;
}

h2 {
    margin-bottom: 25px;
}

section.header {
    text-align: center;
}

section.header .row,
section.header .col {
    height: 230px;
    padding-left: 0;
    padding-right: 0;
}

section.header h1 {
    font-size: 60px;
    margin-bottom: 10px;
}

section.header h2 {
    font-size: 28px;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 0;
}

section.header p.subtitle {
    font-size: 18px;
}

section.section-title {
    width: 100%;
    height: 100%;
    position: relative;
}

.section-container {
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    justify-content: left;
    align-items: left;
    text-align: center;
}

.title {
    font-family: "Quicksand", "Source Sans Pro", -apple-system,
        BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif;
    font-display: swap;
    display: block;
    font-weight: 300;
    font-size: 100px;
    color: #35495e;
    letter-spacing: 1px;
}

.subtitle {
    font-weight: 300;
    font-size: 42px;
    color: #526488;
    word-spacing: 5px;
    padding-bottom: 15px;
}

.links {
    padding-top: 15px;
}

.padding-top-light {
    padding-top: 40px;
}

a.oneline {
    display: -webkit-box;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
    max-height: 21px;
}

a.oneline::before {
    content: "-";
}

@media (max-device-width: 1024px) {
    h1.title {
        font-size: 60px;
    }

    h2.subtitle {
        font-size: 24px;
    }

    .title-container {
        padding-left: 0px;
    }

    #canvas-container {
        padding-left: 0;
        width: 100%;
    }

    .w-75 {
        width: 100% !important;
    }

    .container-fluid {
        padding: 15px;
    }

    h2 {
        margin-top: 20px;
    }

    h2:first-child {
        margin-top: 0px;
    }

    h3 {
        font-size: 18px;
    }

    .section-doc {
        margin-bottom: 0;
    }

    .section-help .col-12 {
        margin-bottom: 30px;
    }
}

.news-layout .v-sidebar-menu .vsm--item {
    white-space: normal;
}

.news-layout .carousel {
    margin-left: -15px;
    margin-right: -15px;
}

.news-layout .carousel-item img {
    max-height: 500px;
    min-width: auto;
    filter: blur(4px);
    filter: brightness(40%);
    max-width: 100%;
    padding: 0;
    object-fit: cover;
}

.news-layout .carousel-item h2 {
    color: white;
    font-size: 36px;
}

.news-layout .carousel-item p {
    color: white;
    font-size: 20px;
}

.sidebar {
    display: none;
}

@media (max-width: 1024px) {
    #actu-carousel,
    #actu-carousel a,
    #actu-carousel .carousel-inner,
    #actu-carousel img {
        height: 300px;
    }

    #actu-carousel h2 {
        font-size: 20px;
    }

    #actu-carousel p {
        font-size: 14px;
    }
}
</style>
