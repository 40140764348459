<template>
    <a v-if="article" :key="article.path" :href="article.path" class="article-shortcut no-decoration">
        <img v-lazy-load :data-src="article.img" />
        <div class="filter-grey">
            <h3 class="mx-auto">{{ article.title }}</h3>
        </div>
    </a>
</template>

<style>
a.article-shortcut {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    box-sizing: border-box;
    overflow: hidden;
}

a.article-shortcut h3 {
    margin: auto;
    text-align: center;
    color: white;
    display: block;
    font-size: 20px;
    width: 100%;
    padding: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
}

a.article-shortcut img {
    width: 100%;
    height: 100%;
    padding: 0;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: 50% 50%;
    background-color: rgba(0, 0, 0, 0.05);
}

a.article-shortcut img:hover {
    transition: transform 0.5s ease;
    transform: scale(1.2);
}

div.filter-grey {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.4);
}
</style>

<script>
export default {
    props: ["article"]
};
</script>
